import React from 'react';

const MyPhotoBlock = () => {
    return (
        <div style={{width: 347, height: 463, boxShadow: '10px -10px 30px #89049F', borderRadius: "50px", marginTop: "180px"}}>
            <img style={{width: 347, height: 463, borderRadius: "50px"}}
                 src="https://i.imgur.com/bL2fEr1.jpeg"/>
        </div>
    );
};

export default MyPhotoBlock;