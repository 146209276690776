import React from 'react';
import SocialButton from "./UI/button/SocialButton";

const Header = () => {
    return (
        <div style={{
            width: "100%",
            height: "80px",
            boxShadow: '0px 4px 50px #89049F',
            marginTop: "-25px",
            marginLeft: "-10px",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#0C0C0D"
        }}>
            <div style={{width: 1440, height: 80, position: 'relative'}}>
                <div style={{width: 1440, height: 80, left: 0, top: 0, position: 'absolute', background: '#0C0C0D'}}/>
                <div style={{width: 65, height: 30.07, left: 1318, top: 25, position: 'absolute'}}>

                </div>
                <div style={{
                    width: 152,
                    height: 40,
                    left: 820,
                    top: 20,
                    position: 'absolute',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 16,
                    display: 'inline-flex'
                }}>

                        <div style={{width: 33.33, height: 33.33}}><SocialButton></SocialButton></div>

                </div>
                <div style={{
                    width: 299,
                    height: 30,
                    left: 467,
                    top: 29,
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 32,
                    display: 'inline-flex'
                }}>
                    <div style={{width: 82, height: 30, position: 'relative'}}>
                        <div style={{
                            width: 82,
                            height: 30,
                            left: 0,
                            top: 0,
                            position: 'absolute',
                            opacity: 0,
                            background: '#D9D9D9'
                        }}/>
                        <div style={{
                            left: 7,
                            top: 3.50,
                            position: 'absolute',
                            color: '#89049F',
                            fontSize: 20,
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>O mnie
                        </div>
                    </div>
                    <div style={{width: 82, height: 30, position: 'relative'}}>
                        <div style={{
                            left: 0,
                            top: 3.50,
                            position: 'absolute',
                            color: 'white',
                            fontSize: 20,
                            fontFamily: 'Raleway',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Projetky
                        </div>
                        <div style={{
                            width: 82,
                            height: 30,
                            left: 0,
                            top: 0,
                            position: 'absolute',
                            opacity: 0,
                            background: '#D9D9D9'
                        }}/>
                    </div>
                    <div style={{
                        color: 'white',
                        fontSize: 20,
                        fontFamily: 'Raleway',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Kontakt
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;