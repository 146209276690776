import React from 'react';
import classes from "./CVButton.module.css";

const CvButton = ({children, ...props}) => {
    return (
        <button {...props} className={classes.CVBtn}>
            {children}
        </button>
    );
};

export default CvButton;