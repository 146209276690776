import React from 'react';
import AboutMe from "./components/AboutMe";
import './styles/App.css'
import MyPhotoBlock from "./components/MyPhotoBlock";
import CvButton from "./components/UI/button/CVButton";
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {

  return (
      <div className="App" style={{display: "flex", flexDirection: "column"}}>
          <div style={{position: "fixed", width: "100%"}}>
              <Header/>
          </div>
          <div style={{marginRight: "90px", marginLeft: "90px"}}>
          <div style={{display: "flex", flexDirection: "row"}}>
              <div style={{marginRight: "20px"}}>
                  <AboutMe/>
              </div>
              <div style={{display: "flex", flexDirection: "column", marginLeft: "20px"}}>
                  <MyPhotoBlock/>
                  <CvButton>Pobierz CV</CvButton>
              </div>
          </div>
          <div style={{
              width: 1253,
              height: 12,
              background: '#89049F',
              boxShadow: '50px 50px 50px ',
              borderRadius: 9999,
              filter: 'blur(50px)'
          }}/>
          </div>
          <div>
              <Footer/>
          </div>

      </div>
  );
}

export default App;
