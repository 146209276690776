import React from 'react';

const Footer = () => {
    return (
        <div style={{width: "100%", height: "80px", textAlign: "center"}}>
            <p>DieMoon © 2024</p>
        </div>
    );
};

export default Footer;