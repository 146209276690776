import React from 'react';
import MyPhotoBlock from "./MyPhotoBlock";
import CvButton from "./UI/button/CVButton";

const AboutMe = () => {
    return (
        <div style={{marginTop: "110px"}}>
            <span
                style={{color: 'white', fontSize: 50, fontWeight: '500'}}>Witam! Jestem </span><span
                style={{color: '#89049F', fontSize: 50, fontWeight: '700'}}>Dmtro Nakutnii</span>

            <p>Jestem studentem <span style={{color: 'white', fontWeight: 'bold'}}> Politechniki Wrocławskiej</span>, posiadającym pewną
                wiedzę z zakresu programowania w językach takich jak <span style={{color: 'white', fontWeight: 'bold'}}>Java, Kotlin, Python oraz podstaw C/C++, JS React</span>.
                Aktywnie uczestniczyłem w projektach, w których wykorzystywałem te języki programowania, zdobywając
                praktyczne umiejętności w ich implementacji
            </p>

            <p>
                Zainteresowany jestem pracą w zespole, gdzie zawsze stawiam sobie za cel pisanie czystego i czytelnego kodu, co staram się konsekwentnie realizować. Moje doświadczenie zdobyte w trakcie projektów skupia się na doskonaleniu umiejętności programistycznych oraz rozwijaniu zdolności do efektywnej współpracy w grupie.
            </p>

            <p>
                W chwili obecnej <span style={{color: 'white', fontWeight: 'bold'}}>poszukuję pracy lub staży</span>, która pozwoli mi rozwijać umiejętności w zakresie tworzenia aplikacji mobilnych, webowych, a także innych projektów programistycznych. Jestem zmotywowany do ciągłego podnoszenia poziomu moich kwalifikacji i chętny do nauki nowych technologii. Chciałbym być aktywnym członkiem zespołu, który kładzie nacisk na innowacyjność i nieustanne doskonalenie, aby wspólnie przyczynić się do realizacji ambitnych projektów.
            </p>

        </div>
    );
};

export default AboutMe;