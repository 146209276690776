import React from 'react';

const SocialButton = () => {
    const redirectToSite = () => {
        window.location.href = 'https://www.linkedin.com/in/dmytro-nakutnii-4b9306269/';
    };

    return (
        <button onClick={redirectToSite} style={{background: "none", border: "none", padding: "0px"}}>
            <img src={"https://i.imgur.com/6bHGCvR.png"}/>
        </button>
    );
};

export default SocialButton;